<template>
    <div>
        <!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper">Your Device Groups</h1> -->
<v-container fluid>
    
    <v-row justify-end no-gutters dense>
        <v-col cols="auto" class="d-flex mr-1">
            <CreateEmailGroupModal  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
        </v-col>
        <v-col cols="auto" class="d-flex mr-1">
            <CreateMachineGroupModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>

        </v-col>
        <v-col cols="auto" class="d-flex mr-1">

            <CreateParamGroupModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
        </v-col>
        <v-col cols="auto" class="d-flex mr-1">

            <CreateUserGroupModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) "/>
        </v-col>
<!-- 
        <v-col cols="auto" class="d-flex mr-1">
            <CreateGroupModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) " />

        </v-col> -->
        <!-- <v-col v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        </v-col>
        <v-col v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        </v-col>
        <v-col v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        </v-col>
        <v-col v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) ">
        </v-col>
        <v-col  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) " >
        </v-col> -->
    </v-row>
    <v-row no-gutters>
        <v-col>
        <DepartmentTable />
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
        <NotificationGroupTable />
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
        <UserGroupTable />
        </v-col>
    </v-row>
    <v-row no-gutters v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-col>
        <MachineGroupTable />
        </v-col>
    </v-row>
    <v-row no-gutters v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])" >
        <v-col>
        <ParamGroupTable />
        </v-col>
    </v-row>
    <!-- <v-row no-gutters><GroupList/></v-row> -->
    <!-- <v-row v-if="$store.state.user && $store.state.user.organisation_id==='26c7ea9e-8d15-4022-bd9e-e4166a6078e5' && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) "><ParamDummyList/></v-row>
         -->
</v-container>
    </div>
</template>

<script>
// import GroupList from '@/components/lists/GroupList'
// import ParamDummyList from '@/components/lists/ParamDummyList'
// import CreateGroupModal from '@/components/modals/CreateGroupModal'
import CreateEmailGroupModal from '@/components/modals/CreateEmailGroupModal'
import CreateMachineGroupModal from '@/components/modals/CreateMachineGroupModal'
import CreateParamGroupModal from '@/components/modals/CreateParamGroupModal'
import CreateUserGroupModal from '@/components/modals/accounts/CreateUserGroupModal'
import NotificationGroupTable from '@/components/lists/NotificationGroupTable'
import UserGroupTable from '@/components/lists/UserGroupTable'
import MachineGroupTable from '@/components/lists/MachineGroupTable'
import ParamGroupTable from '@/components/lists/ParamGroupTable'
import DepartmentTable from '@/components/lists/DepartmentTable.vue'
export default {
    name:'Groups',
    components:{
        // GroupList,
        // ParamDummyList,
        // CreateGroupModal,
        CreateEmailGroupModal,
        NotificationGroupTable,
        UserGroupTable,
        MachineGroupTable,
        ParamGroupTable,
        CreateMachineGroupModal,
        CreateParamGroupModal,
        CreateUserGroupModal,
        DepartmentTable
    },
    mounted() {
        document.title='Groups'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    }
}
</script>